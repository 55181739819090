// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--lzimX";
export var alternativeFooter = "Footer-module--alternativeFooter--c9bh1";
export var sectionTitle = "Footer-module--sectionTitle--pzSYn";
export var logoWrapper = "Footer-module--logoWrapper--8QjOo";
export var withSocialLogos = "Footer-module--withSocialLogos--5PW7R";
export var contactWrapper = "Footer-module--contactWrapper--lsbXP";
export var contactLinksWrapper = "Footer-module--contactLinksWrapper--c2ZAV";
export var contactLinks = "Footer-module--contactLinks--drgtx";
export var emailWrapper = "Footer-module--emailWrapper--xNUPT";
export var scheduleCallWrapper = "Footer-module--scheduleCallWrapper--qIp-j";
export var social = "Footer-module--social--ASzrs";
export var list = "Footer-module--list--kfhuK";
export var resources = "Footer-module--resources--WNdbO";
export var country = "Footer-module--country--IR4wi";
export var disclaimers = "Footer-module--disclaimers--MJeKK";
export var euFederLogos = "Footer-module--euFederLogos--7A9W5";
export var euFederLogosList = "Footer-module--euFederLogosList--xC6Dr";
export var countrySwitch = "Footer-module--countrySwitch--HYQYP";