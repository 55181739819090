// extracted by mini-css-extract-plugin
export var navigation = "Navigation-module--navigation--iUBMD";
export var mainNavigation = "Navigation-module--mainNavigation--faWTY";
export var navigationFunnel = "Navigation-module--navigationFunnel--D7wAP";
export var brand = "Navigation-module--brand--gHXZb";
export var expandable = "Navigation-module--expandable--70bdC";
export var main = "Navigation-module--main--AfeQT";
export var icon = "Navigation-module--icon--2srOb";
export var readMore = "Navigation-module--readMore--Xbamz";
export var expandableContent = "Navigation-module--expandableContent--Myk0s";
export var menuUser = "Navigation-module--menuUser--Robf6";
export var navigationOnboarding = "Navigation-module--navigationOnboarding--e7hnn";
export var navigationStuck = "Navigation-module--navigationStuck--4ogWH";
export var navigationCta = "Navigation-module--navigationCta--hdhAk";