import { CountryCode } from "../../../settings/countries";

export interface BundleId {
  petKbKey: string;
  policyId: string;
}

export interface BundleDTO {
  country: CountryCode;
  email: string;
  monthlyPremiumInCents: number;
  paymentIntervalMonths: number;
  premiumInCents: number;
  renewable: boolean;
}

export class Bundle implements BundleDTO {
  readonly country: CountryCode;
  readonly email: string;
  readonly monthlyPremiumInCents: number;
  readonly paymentIntervalMonths: number;
  readonly premiumInCents: number;
  readonly renewable: boolean;
  constructor(attrs: BundleDTO) {
    Object.assign(this, attrs);
  }

  get monthlyPremium(): number {
    return this.monthlyPremiumInCents / 100;
  }
}
