import { CountryCode } from "../../../settings/countries";
import { fetchApi, fetchApiJson } from "../../../utils/request";
import { rollbar, RollbarLogArgument } from "../../../utils/rollbar";
import { BundleDTO, BundleId } from "../domain/bundle";

function bundleUrl(bundleId: BundleId): string {
  return `${process.env.GATSBY_API_BASE}/bundles/${bundleId.petKbKey}/${bundleId.policyId}/`;
}

export async function getBundle(bundleId: BundleId): Promise<BundleDTO | null> {
  try {
    const json: Record<string, unknown> = await fetchApiJson(bundleUrl(bundleId));

    return {
      email: json.email as string,
      country: json.country as CountryCode,
      monthlyPremiumInCents: json.monthly_premium_in_cents as number,
      paymentIntervalMonths: json.payment_interval_months as number,
      premiumInCents: json.premium_in_cents as number,
      renewable: json.renewable as boolean,
    };
  } catch (err) {
    if (err instanceof Error) {
      rollbar.error(err.message, err as RollbarLogArgument, { context: "getBundle" });
    }

    return null;
  }
}
export async function switchToMonthly(bundleId: BundleId): Promise<Record<string, unknown>> {
  return await fetchApi(`${bundleUrl(bundleId)}switch_to_monthly`, {
    method: "POST",
  });
}
