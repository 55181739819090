import { atom, selector } from "recoil";

import { BundleDTO, BundleId } from "../domain/bundle";
import { getBundle } from "../repositories/bundle-repository";

export const bundleIdState = atom<BundleId | null>({
  key: "bundleIdState",
  default: null,
});

export const bundleStateSelector = selector<BundleDTO | null>({
  key: "bundle",
  get: async ({ get }) => {
    const currentBundleId = get(bundleIdState);
    if (!currentBundleId) {
      return null;
    }

    return await getBundle(currentBundleId);
  },
  set: ({ set }, newData) => {
    set(bundleState, newData);
  },
});

export const bundleState = atom({
  key: "bundleState",
  default: bundleStateSelector,
});
