import { useCallback } from "react";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { States } from "../../../settings/state";
import { Bundle, BundleId } from "../domain/bundle";
import { switchToMonthly } from "../repositories/bundle-repository";
import { bundleIdState, bundleState } from "../state/atoms";

export function useSetBundleId(): (bundleId: BundleId) => void {
  const setBundleId = useSetRecoilState(bundleIdState);

  return useCallback((bundleId: BundleId) => setBundleId(bundleId), [setBundleId]);
}

export function useBundle(): Bundle | States.LOADING_STATE | null {
  const bundle = useRecoilValueLoadable(bundleState);
  switch (bundle.state) {
    case "hasValue":
      if (!bundle.contents) {
        return null;
      }

      return new Bundle(bundle.contents);
    case "hasError":
      throw bundle.contents;
    case "loading":
      return States.LOADING_STATE;
    default:
      return null;
  }
}

export function useSwitchBundleToMonthly(): (bundleId: BundleId) => Promise<boolean> {
  return useCallback(async (bundleId: BundleId) => {
    try {
      await switchToMonthly(bundleId);

      return true;
    } catch (e) {
      return false;
    }
  }, []);
}
