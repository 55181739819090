import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useEffect } from "react";

import DogBillsIllustration from "../../../../../images/pages/bundles/dog-bills.svg";
import SectionTitle from "../../../../../molecules/section-title/SectionTitle";
import { useBundle } from "../../../../../organisms/bundle/use-cases/bundle-use-cases";
import Footer from "../../../../../organisms/footer/AlternativeFooter";
import Meta from "../../../../../organisms/meta/meta";
import Navigation from "../../../../../organisms/navigation/Funnel";
import PageWrapper from "../../../../../organisms/page-wrapper/PageWrapper";
import { States } from "../../../../../settings/state";
import * as styles from "../../../../../templates/SwitchToMonthly.module.scss";
import { Events, track } from "../../../../../utils/analytics";
import { homePath } from "../../../../../utils/locale-configuration-utils";

const Page = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const bundle = useBundle();
  const bundleIsLoading = bundle === States.LOADING_STATE;
  const bundleIsLoaded = !bundleIsLoading && !!bundle;
  const title = t("bundle.switch_to_monthly.success.title");

  useEffect(() => {
    track(Events.SWITCH_TO_MONTHLY_SUCCESS_VIEWED_BROWSER);
  }, []);

  return (
    <PageWrapper>
      <Meta
        title={title}
        lang={i18n.language}
        bodyClass={styles.switchToMonthly}
        description={undefined}
        alternateVersions={false}
        slug={undefined}
        disableTranslations={true}
      />
      <Navigation email={bundleIsLoaded ? bundle.email : undefined} />
      <main id="main" className="or-main">
        <div className={styles.wrapper}>
          <DogBillsIllustration
            className={styles.illustration}
            aria-hidden="true"
            focusable="false"
          />

          <SectionTitle
            adoptionClassName={styles.header}
            title={title}
            subtitle={t("bundle.switch_to_monthly.success.description")}
          />

          <Link
            className={styles.successCta}
            to={homePath(bundle && bundle !== States.LOADING_STATE ? bundle.country : undefined)}
          >
            {t("bundle.switch_to_monthly.success.cta")}
          </Link>
        </div>
      </main>

      <Footer />
    </PageWrapper>
  );
};

export default Page;

export const query = graphql`
  query SwitchToMonthlyErrorQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
