import { FunctionComponent, ReactNode } from "react";

export type HeadingLevel = 1 | 2 | 3 | 5 | 6 | number;

interface HeadingProps {
  level: HeadingLevel;
  children?: ReactNode;
  adoptionClassName?: string;
  id?: string;
}

type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const Heading: FunctionComponent<HeadingProps> = ({
  level,
  children = null,
  adoptionClassName,
  id,
}) => {
  const Tag = `h${level <= 6 ? level : 6}` as HeadingTag;

  return (
    <Tag className={adoptionClassName} id={id}>
      {children}
    </Tag>
  );
};

export default Heading;
